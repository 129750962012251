.header-container {
    height: 40px;
    background-color: black;
}
nav {
    text-align:center;
    li {
        display:inline-block;
        list-style:none;
        font-weight:bold;
    }
}
.local-header-wrapper{
    background-color: rgb(33, 33, 33);
    padding:5px 0px;
    .root-48{
        padding: 0px;
    }
}

@media (min-width: 640px) {
    .header-wrapper {
        margin-left: 10%;
        margin-right: 10%;
    }
}
